<template>
  <div class="card card-custom card-transparent">
    <div v-if="submitStatus === 'ERROR'">
      <b-alert show variant="danger"
        ><i class="fas fa-exclamation-circle text-white mr-3"></i>

        There are a missing fields, please sure to fill all the data</b-alert
      >
    </div>
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-4"
        id="kt_wizard_v4"
        data-wizard-state="step-first"
        data-wizard-clickable="true"
      >
        <!--begin: Wizard Nav-->
        <div class="wizard-nav">
          <div class="wizard-steps">
            <div
              class="wizard-step"
              data-wizard-type="step"
              data-wizard-state="current"
            >
              <div class="wizard-wrapper">
                <div class="wizard-number">1</div>
                <div class="wizard-label">
                  <div class="wizard-title">New Zone</div>
                  <div class="wizard-desc">Add New Zone</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body-->
        <div class="card card-custom card-shadowless rounded-top-0">
          <div class="card-body p-0">
            <div class="row justify-content-center py-8 px-8 py-lg-15 px-lg-10">
              <div class="col-xl-12 col-xxl-7">
                <!--begin: Wizard Form-->
                <form class="form mt-0 mt-lg-10" id="kt_form">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.zone.name_ar.$error }"
                    >
                      <label class="form__label">Name(Arabic)</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        placeholder="Name in Arabic"
                        v-model="zone.name_ar"
                        :class="{ 'form-input--error': $v.zone.name_ar.$error }"
                        :state="$v.zone.name_ar.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        This field is mandatory.
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.zone.name_en.$error }"
                    >
                      <label class="form__label">Name(English)</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        placeholder="Name in English"
                        v-model="zone.name_en"
                        :class="{ 'form-input--error': $v.zone.name_en.$error }"
                        :state="$v.zone.name_en.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        This field is mandatory.
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{ 'form-group--error': $v.zone.price.$error }"
                    >
                      <label class="form__label">Price(JOD)</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        placeholder="Zone Price"
                        v-model="zone.price"
                        :class="{ 'form-input--error': $v.zone.price.$error }"
                        :state="$v.zone.price.$error === true ? false : null"
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        This field is mandatory.
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.zone.displayed_price.$error,
                      }"
                    >
                      <label class="form__label">Displayed Price(JOD)</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        placeholder="Displayed  Price"
                        v-model="zone.displayed_price"
                        :class="{
                          'form-input--error': $v.zone.displayed_price.$error,
                        }"
                        :state="
                          $v.zone.displayed_price.$error === true ? false : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        This field is mandatory.
                      </p>
                    </div>

                    <div
                      class="form-group"
                      :class="{
                        'form-group--error': $v.zone.tookan_region_id.$error,
                      }"
                    >
                      <label class="form__label">Tookan Region ID</label>
                      <b-form-input
                        type="text"
                        class="form-control form-control-solid form-control-lg form__input"
                        name="fname"
                        placeholder="Tookan Region ID"
                        v-model="zone.tookan_region_id"
                        :class="{
                          'form-input--error': $v.zone.tookan_region_id.$error,
                        }"
                        :state="
                          $v.zone.tookan_region_id.$error === true
                            ? false
                            : null
                        "
                      />
                      <p class="typo__p" v-if="submitStatus === 'ERROR'">
                        This field is mandatory.
                      </p>
                    </div>

                    <div
                      class="d-flex justify-content-between border-top pt-10"
                    >
                      <div class="mr-2"></div>
                      <div>
                        <button
                          @click.prevent="submit"
                          class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                          data-wizard-type="action-submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
                <!--end: Wizard Form-->
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Bpdy-->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>


<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import KTWizard from "@/assets/js/components/wizard";
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

export default {
  name: "CreateQueue",

  data() {
    return {
      zone: {
        name_ar: null,
        name_en: null,
        price: null,
        tookan_region_id: null,
        displayed_price: null,
      },
      submitStatus: null,
    };
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Zones", route: "/admin/zones" },
      { title: "New Zone" },
    ]);

    // Initialize form wizard
    const wizard = new KTWizard("kt_wizard_v4", {
      startStep: 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    // Validation before going to next page
    wizard.on("beforeNext", function (/*wizardObj*/) {});

    // Change event
    wizard.on("change", function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, 500);
    });
  },
  methods: {
    updateClients(value) {
      this.queue.clients = value;
    },
    updateDrivers(value) {
      this.queue.drivers = value;
    },
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.submitStatus = "ERROR";
      } else {
        await this.$store.dispatch("zones/create", this.zone);

        Swal.fire({
          title: "",
          text: "The queue has been created successfully!",
          icon: "success",
          confirmButtonClass: "btn btn-secondary",
        });

        this.$router.push({ name: "ZonesManager" });
        this.$store.dispatch("zones/loadAll");

        this.submitStatus = "PENDING";
        setTimeout(() => {
          this.submitStatus = "OK";
        }, 500);
      }
    },
  },
  validations: {
    zone: {
      name_ar: {
        required,
      },
      name_en: {
        required,
      },
      price: {
        required,
      },
      tookan_region_id: {
        required,
      },
      displayed_price: {
        required,
      },
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-4.scss";
</style>

<style scoped>
.form-group--error {
  color: rgba(255, 0, 0, 0.8);
}
.form-input--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}
.form-input--error:focus {
  border: rgba(255, 0, 0, 0.5) 1px solid;
}

.form-select--error {
  border: rgba(255, 0, 0, 0.5) 1px solid;
  border-radius: 5px;
}
</style>